<template>
   
   <div class="h-auto p-2 flex flex-col mb-2 shadow_module rounded-lg" :class="'bg-box-'+mode">
        <div class="h-6 flex flex-row justify-start items-center px-2">
            <span class="text-xs font-semibold" :class="'text-dfont-'+mode">{{data.name}}</span>
        </div>
        <div class="h-auto flex flex-wrap">
            <div v-if="data.growth != null" class="h-8 w-1/3 flex flex-col justify-between items-center">
                <span class="text-2xs font-medium" :class="'text-font_gray-'+mode">{{topindicatorname}}<sup v-if="topindicatorname == 'Metros'">2</sup></span>
                <span class="text-xs font-medium" :class="'text-dfont-'+mode">{{data.value | reduceBigNumbers()}}<span v-html="unit"></span></span>
            </div>
            <div v-if="data.growth != null" class="h-8 w-1/3 flex flex-col justify-between items-center">
                <span class="text-2xs font-medium" :class="'text-font_gray-'+mode">{{$t('growth')}}</span>
                <span class="text-xs font-medium" :class="{'text-green': data.growth > 0, 'text-red': data.growth <= 0}"><span v-if="data.growth > 0">+</span>{{data.growth | reduceBigNumbers()}}<span v-html="unit2"></span></span>
            </div>
            <div v-if="data.weighing != null" class="h-8 w-1/3 flex flex-col justify-between items-center">
                <span class="text-2xs font-medium" :class="'text-font_gray-'+mode">{{$t('weight')}}</span>
                <span class="text-xs font-medium" :class="'text-dfont-'+mode"><span v-if="data.weighing > 0 && topindicator > 1">+</span>{{data.weighing | reduceBigNumbers()}}<span v-html="unit2"></span></span>
            </div>
        </div>
    </div>

</template>

<script>

    export default {
        props:['data', 'mode', 'topindicatorname', 'unit2', 'unit', 'topindicator'],
        data(){
            return{

            }
        } 
    }
</script>

<style scoped>

</style>