<template>

    <div class="w-full h-full px-3 py-4" :class="'bg-body-'+mode">

        <div class="h-full w-full flex flex-col mb-6 overflow-auto">

            <All model="Grouptops" :immediate="true" :query="query"  :data.sync="datatops" v-slot="{data,loading}">
                <div v-if="!loading" class="h-auto">

                    <top v-for="(el,index) in data.table" :key="index" :data="el" :mode="mode" :topindicatorname="topindicatorname" :unit2="unit2" :unit="unit" :topindicator="topindicator"/>

                </div>

                <div v-else class="h-20 relative">
                    <loader :loading="loading" />
                </div>

            </All>

        </div>

    </div>

</template>

<script>
import basiclinechart from '@/components/basiclinechart.vue';
import { All } from '@/api/components';
import { state, actions } from '@/store';
import loader from '@/components/loader.vue';
import top from '../../components/top.vue';

export default {
    components: {
        basiclinechart,
        All,
        loader,
        top
    },
    data() {
        return {
            datatops: null
        }
    },
    methods:{
        translate(word) {
            switch (word) {
                case 'Paises':
                    return this.$t("countries")
                    break;
                case 'SuperFamilias':
                    return this.$t("superFamily")
                    break;
                case 'Facturación':
                    return this.$t("billing")
                    break;
                case 'Provincias de España':
                    return this.$t("spanishProvinces")
                    break;
                case 'Todos los continentes':
                    return this.$t("allContinents")
                    break;
                case 'Continentes':
                    return this.$t("continents")
                    break;
                case 'Formatos':
                    return this.$t("formats")
                    break;
                case 'Clientes':
                    return this.$t("customers")
                    break;
                case 'Calidad':
                    return this.$t("quality")
                    break;
                case 'Marcas':
                    return this.$t("brands")
                    break;
                case 'Modelos':
                    return this.$t("models")
                    break;
                case 'Colores':
                    return this.$t("colors")
                    break;
                case 'Todos':
                    return this.$t("all")
                    break;
                default:{
                    return word
                }
            }
        },
        toFilters() {
            actions.setLastPage(this.$route.name)
            this.$router.push('filterstop')
        }
    },
    computed:{
        mode(){
            return state.mode
        },
        query() {
            return {
                period: state.topPeriod,
                Dimension: state.topIndicator,
                Group: state.topDimension,
                Place: state.topTypePlace,
                PlaceId: state.topPlace,
                ClientType: state.typeclienttops,
                month: state.month
            }
        },
        periodo() {
            switch (state.topPeriod) {
                case 'month':
                    return this.$t("monthly")
                    break;
                case 'quarter':
                    return this.$t("quarterly")
                    break;
                case 'year':
                    return this.$t("annual")
                    break;
            }
        },

        monthName(){
            return this.$moment({M: state.month -1 }).format('MMMM')
        },

        unit(){
            let unit = ' €'
            if (state.topIndicator == 1) { unit = ' m<sup>2</sup>' }
            if (state.topIndicator == 3) { unit = ' %' }
            if (state.topIndicator == 2) { unit = '€/m<sup>2</sup>' }
            return unit
        },
        unit2() {
            let unit = ' %'
            if (state.topIndicator == 2){ unit = '€/m<sup>2</sup>' }
            if (state.topIndicator == 3){ unit = '' }
            return unit
        },
        topdimension() {
            return state.topDimension 
        },
        topplace(){ 
            return state.topPlace 
        },
        topindicator(){ 
            return state.topIndicator 
        },
        topdimensionname(){ 
            return state.topDimensionName 
        },
        topindicatorname(){
            if (state.topIndicatorName !== 'Metros cuadrados') {
                if (state.topIndicatorName === 'Facturación') { return this.$t("billing") }
                if (state.topIndicatorName === 'Precio medio') { return this.$t("averagePrice") }
                if (state.topIndicatorName === 'Margen') { return this.$t("margin") }
                else { return state.topIndicatorName }
            }
            else { return this.$t("meters")}
        },
        topplacename(){ 
            return state.topPlaceName 
        }
    }
}
</script>
